<template>
<div id="user-profile">
    <b-row>
        <b-col lg="5" cols="12" order="1" order-lg="1">
            <b-card class="profile-header mb-2" body-class="p-0">
                <b-card-body>
                    <b-row class="mb-2">
                        <b-col cols="12" md="6" class="mb-md-0 mb-2">
                            <label>Sınıf/Ders</label>
                            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedLesson" :options="lessons" class="w-100" :getOptionLabel="option => option.class.name+' / '+option.lesson.name" :reduce="val => val.id" />
                        </b-col>

                        <b-col cols="12" md="6" class="mb-md-0 mb-2">
                            <label>Alt Sınıf</label>
                            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="childClassId" :options="childClass" class="w-100" :reduce="val => val.class" :getOptionLabel="option => option.class.name" />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="12" class="mb-md-0 mb-2">
                            <b-button block variant="success" @click="getStudent()">FİLTRELE</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-card no-body>
                <b-table :items="users" responsive :fields="questionFields" class="mb-0">
                    <template #cell(fullName)="data">
                        <span>
                            {{data.item.firstName}} {{data.item.lastName}}
                        </span>
                    </template>
                    <template #cell(action)="data">
                        <b-button block variant="flat-primary" class="font-weight-bolder mb-12" @click="getReport(data.item)">
                            {{data.item.isDeleted?'Yüklendi':'SEÇ'}}
                        </b-button>
                    </template>
                </b-table>
            </b-card>
        </b-col>
        <b-col lg="7" cols="12" order="2" order-lg="2">
            <b-card class="profile-header mb-2" body-class="p-0">
                <b-card-body>
                    <div class="position-relative">
                        <b-row>
                            <b-col lg="8" cols="12" order="1" order-lg="1" class="mb-2">
                                <b-form-file v-model="optickFile" placeholder="Optik Form Seçin" drop-placeholder="Burakaya sürükle..." browse-text = "Resmi Çek" />
                            </b-col>
                            <b-col lg="2" cols="12" order="1" order-lg="1" class="mb-2">
                                <b-button block variant="success" @click="setOptick(false)">Kontrol Et</b-button>
                            </b-col>
                            <b-col lg="2" cols="12" order="1" order-lg="1" class="mb-2">
                                <b-button block variant="success" @click="setOptick(true)">Yükle</b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12" cols="12" order="1" order-lg="1" class="mb-2">
                                <span>Opaklık Seviyesi (resim karanlık ise arttırın,fazla parlak ise azaltın)</span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12" cols="12" order="1" order-lg="1" class="mb-2">
                                <vue-slider v-model="threshold" :adsorb="true" :included="true" :min="70" :max="150" :marks="marks" class="mb-3" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <img :src="optickSrc" style="width: 100%;height: auto;" />
                        </b-row>
                    </div>
                </b-card-body>
            </b-card>
            <b-overlay :show="loading" no-wrap>
            </b-overlay>
        </b-col>
    </b-row>
</div>
</template>

<script>
import { BOverlay, BProgress, BProgressBar, BRow, BCol, BCard, BCardBody, BImg, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BButton, BTable, BAvatar, BFormFile } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import vSelect from 'vue-select'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import axios from "axios"
export default {
    components: {
        VueSlider,
        BFormFile,
        BOverlay,
        BProgressBar,
        BProgress,
        BTable,
        BAvatar,
        vSelect,
        AppTimeline,
        AppTimelineItem,
        BRow,
        BCol,
        BCard,
        BCardBody,
        BImg,
        BNavbar,
        BNavbarToggle,
        BCollapse,
        BTabs,
        BNavItem,
        BButton,
    },
    watch: {
        selectedLesson: function (val) {
            this.childClass = [];
            this.childClassId = null;
            if (val != null) {
                var filterData = this.lessons.filter(x => x.id == val)[0];
                this.childClass = filterData.childClass;
            }
        },
    },
    data() {
        return {
            loading: false,
            selectedMenu: 0,
            selectedLesson: '',
            childClassId: '',
            users: [],
            lessons: [],
            childClass: [],
            questionFields: [
                { key: 'fullName', label: 'Öğrenci Adı Soyadı' },
                { key: 'action', label: 'İşlem' },
            ],
            selectedUser: {},
            videos: [],
            tests: [],
            lacks: [],
            threshold: 100,
            marks: [70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150],
            optickFile: null,
            optickSrc: null
        }
    },
    created() {
        this.getLesson();
    },
    methods: {
        async getLesson() {
            var lesson = await this.$http.get("Teacher/Lessons");
            this.lessons = lesson.data.data;
        },
        async getStudent() {
            var users = await this.$http.get("Teacher/StudentsChildClass/" + this.childClassId.id);
            this.users = users.data.data;
        },
        async getReport(user) {
            this.selectedUser = user;
            this.optickSrc = null;
            this.optickFile = null;
        },
        setUserRead() {
            this.users.filter(x => x.id == this.selectedUser.id)[0].isDeleted = true;
        },
        setOptick(isSave) {
            this.loading = true;
            var request = new FormData();
            request.append("file", this.optickFile);
            request.append("userId", this.selectedUser.id);
            request.append("threshold", this.threshold);
            request.append("isSave", isSave);
            this.optickSrc = "";
            axios.post("https://optik.uzaktanokulsistemi.com/Optik/Scanner", request).then(resp => {
                if (isSave == true) {
                    this.setUserRead();
                    alert("Kaydedildi.");
                }
                this.optickSrc = resp.data;
                this.loading = false;
            }).catch(() => {
                alert("Hatalı İşlem!")
                this.loading = false;
            });
        },
        getTestVariant(status) {
            switch (status) {
                case "HasResulted":
                    return "success";
                case "Waiting":
                    return "info";
                case "Processing":
                    return "warning";
            }
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
